var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"statistics"},[(_vm.resultStats)?_c('v-simple-table',{staticClass:"stats-table"},[_c('tr',[_c('td',[_vm._v("Новые статьи")]),_c('td',[_vm._v(" "+_vm._s(_vm.resultStats.editsStats.japNew)+" "),(_vm.userHasRights(-1))?_c('span',[_vm._v(" / "+_vm._s(_vm.resultStats.editsStats.japEditAuto)+" (авто) ")]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Исправления")]),_c('td',[_vm._v(" "+_vm._s(_vm.resultStats.editsStats.japEdit)+" "),(_vm.userHasRights(-1))?_c('span',[_vm._v(" / "+_vm._s(_vm.resultStats.editsStats.japNewAuto)+" (авто) ")]):_vm._e()])])]):_vm._e(),_c('v-divider',{staticClass:"divider-stats"}),(_vm.resultStats)?_c('table',{staticClass:"user-stats-table"},[_c('thead',[_c('tr',[_c('td'),_c('td'),_c('td'),_c('td',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Р.")])]}}],null,false,2757072689)},[_c('span',[_vm._v("Суммарный рейтинг")])])],1),_c('td',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Н.")])]}}],null,false,2155895500)},[_c('span',[_vm._v("Новые статьи")])])],1),_c('td',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("И.")])]}}],null,false,2336473481)},[_c('span',[_vm._v("Исправления статей")])])],1)])]),_vm._l((_vm.lastUserStats),function(user,index){return _c('tr',{key:user.id},[_c('td',[_vm._v(_vm._s(index + 1)+".")]),_c('td',[_c('v-avatar',{staticClass:"avatar",attrs:{"size":"26px"}},[_c('img',{attrs:{"src":_vm.avatarLink(user.avatar)}})])],1),_c('td',{staticClass:"stats-username"},[_c('router-link',{attrs:{"to":{ name: 'user', params: { username: user.username } }}},[_c('span',{staticClass:"stats-username-str"},[_vm._v(_vm._s(user.username))])])],1),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(user.userRating.weekRating))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(user.userRating.summary.week.japNew))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(user.userRating.summary.week.japEdit))])])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }